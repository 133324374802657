export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلام "])},
  "navbar": {
    "primaryPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاپەرِەى سەرەكى"])},
    "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سەرەكى "])},
    "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دەربارەى ئێمة "])},
    "using": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رِێبةرى بەكارهێنان "])},
    "Q&A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأسئلة الشائعة "])},
    "downloadapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داگرتنى كاربەرنامەكە  "])}
  },
  "home": {
    "cardTmwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پسوولەى بەشەخۆراك  "])},
    "starthometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەر ئێستا دەست بە مامەڵةگەت بكە و بە ئاسانى لە بەكارهێنانى كۆمپيوتەري تايبەتى خۆت يان مۆبايلەكەت ، و بةخۆت رِاستەوخۆ بەدواچوونى مامةڵةكەت بكە ."])},
    "startbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەر ئێستا دەست بە مامەڵةگەت بكە "])},
    "abouttmwin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پوختەيەك دەربارەى پسوولەى خۆراكى ئەلكترۆنى"])},
    "abouttmwindesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پڕِۆژەيەكە بۆ بۆ ئەلكترۆنى كردنى سوولەى بەشەخۆراكى لە ڕِێگەى ئەپليكەيشنێكى تايبەتەوە بۆ هەردوو بريكار و هاووڵاتى و جێبەجێكردنى پرۆسەى گياندنى بەشەخۆراك ئاسان دةكات و هەروەها هەموو مامەڵَة پةيوەستەكان بە هەمواركردنەوە وەك زيادكردن يان سڕينەوەى كەسەكان و هەمواركردنەوەى تر . پڕۆژەى كارتى ئەلكترۆنى چارەسەريَكى باوڕِپێكراو و ئاسانى بەكارهێنانە بۆ هەموو ئەو خێزانانەى سوود لە بەرنامەكە وردەگرن و هەروەها كارمەندانى حوكومەتى عێراق ."])},
    "usingtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڕيَبەرى پسوولەى بەشەخۆراكى ئەلكترۆنى"])},
    "usingdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئەم ڕێبەرە يارمەتيدەر دەبێت بۆ پێشكەشكردن بە شێوةيەكى دروست . "])},
    "card1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیاری دروستی خۆت بنووسە:"])},
    "card1desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لە كاتى پێشكەشكردنى پسوولەى بەشەخۆراكى ئەلكترۆنى ، تكايە ئاگات لە داخڵكردنى زانيارى كەسى خۆت بكەرەوە( ناو ، ژمارەى كارتى نيشتمانى ، ناونيشان ، زانيارى سەنترى بەشەخۆراك) بە شێوەيەكى ڕاست و دروست بۆ خۆ بە دوورگرتن لە لێپرسينەوەى ياسايى"])},
    "card2title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى تەلەفۆنى دروست بەكاربێنە :"])},
    "card2desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لە كاتي تۆمارى كاربەرنامەكە ، تكايە ژمارەى تەلەفۆنى دروست بەكاربهێنە بۆ ئەوەى پةسەند بكرێت بۆ مامەلَەكان و هەمواركردنەوە و ئاگاداركردنەوەكانى دواتر ، و زياتر لە جارێك تۆمار مەكە . "])},
    "card3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڕەگەزنامە فەرمييە ڕاستەكان بەكاربهێنە  :"])},
    "card3desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لە كاتى داواكردن تكايە بەڵگەنامە فەرمييەكانى ناسينەوەكەت تێبنووسە (كارتى نيشتمانى ، كارتى نيشتەجێبوون ، ثسوولەى كۆنى بەشەخۆراك) بۆ دڵنيابوون لە پرۆسەيەكى تۆماركردنى ڕاست و دروست ."])},
    "card4title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سەرنجى ئاگانامەكان بدە :"])},
    "card4desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكايە گرنگى بەو ئاگاداريانە بدەن كە لە ئەپلكێشنى مۆبايل وەريدەگرن بۆ زانينى بەروارەكانى وەرگرتنى بەشەخۆراك و ژمارەى بابەتەكانى ."])},
    "allright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەموو مافەكان پارێزراون بۆ پلاتفۆرمى پسوولى خۆراكى 2023"])},
    "questins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پرسيارە باوەكان :"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لە كاتى وەرگرتنى بەشە خۆراک چ پسوولەيەك بەكار دەهێنرێت ؟"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەشەخۆراكەكە لە ڕێگەى كارتى نيشتمانى خۆتەوە وەردەگريت (كارتى يەكگرتوو) لە ئەگەرى ئەوەى كە كارتێكتان نەبێت ، كارتێكى كاتى وەردەگرن تا دابينكردنى كارتى يەكگرتوو تايبەت بە خۆت ."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چۆن دەتوانم بەروارەكانى وەرگرتنى بەشە خۆراكەكە بزانم ؟"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لە كاتى گەيشتنى بەشەخۆراكەكەت لە رێگەى ئاگاداركردنەوەكانى سەر ئەپليكەيشنى مۆبايلەوە ئاگادارتان دەكەينەوە ."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئايا كارتى بەشە خۆراكى كاغەزى پشتگوێ دەخرێت ؟"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەڵێ، كارتى ئێستاى بةشةخۆراكى كاغةزى دەگۆڕدرێت بۆ كارتى بەشەخۆراكى ئەليكترۆنى. "])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەرنامەكە چ ئەركێكى ترپێشكةش دەكات ؟"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئەپليكەيشنەكە تواناى ئەوەت پێدەدات هەموو دەستكارييەك لە پسوولەى بەشەخۆراكەكەتدا بكەيت ، وەك پێشكەشكردنى داواكارى بۆ سرِينەوە و زيادكردنى تاك و كارەكانى تر ، هەروەها چاودێريكردنى دۆخى مامەڵەكەت بەبێ ئەوەى پێويست بكات بچيتە ناوەندكانى بەشەخۆراكى ."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئايا برنامەكە كاريگەرە لەسەر هەموو ئامێرەكان ؟"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەرنامەكە كاريگەرە لەسەر هەموو ئەو ئامرانەى كە بە سيستەمى ئەندرۆيد و سيتەمى الـ IOS كاردەكەن ."])},
    "downloadapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئێستا بەرنامەكە دابەزێنە : "])},
    "regsternow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەشيَوەيەكى ڕاستەخۆ تۆمار بكە "])},
    "followus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوامان بكەوە لەسەر "])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حال كنت من ذوي الهمم يرجى التسجيل و انتظار فريقنا المختص للوصول اليك و اكمال معاملتك بصورة صحيحة من منزلك"])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف سيتم تدقيق معاملتي في حال كنت من ذوي الهمم و تعذر علي الوصول الى احدى المراكز؟"])},
    "a7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك استعادة رقم المستخدم عن طريق الضغط على خيار \"نسيت الرمز السري\" وإدخال رقم الهاتف وتأكيده، سيظهر بعدها اسم المستخدم الذي يمكن نسخه وحفظه ويمكنك كذلك تغيير الرمز السري."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حال نسيان او ضياع رقم المستخدم، هل يمكنني استرجاع الرقم؟"])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل من الضروري حضور جميع افراد الاسرة الى مراكز التدقيق ؟"])},
    "a8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ان حضور جميع افراد الاسرة لمن تتجاوز أعمارهم الـ18ًسنة لمراكز التدقيق الزاميا لضمان تسليم الحصة التموينية للأفراد المستحقين فقط."])},
    "q9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل يمكنني تحديد موعد جديد في حال لم أستطعالحضور في الموعد المحدد؟"])},
    "a9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم، يمكنك اختيار موعد جديد من خلال التطبيق او الموقع الالكتروني."])},
    "q10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذاَّعليمراجعةمراكز التدقيقبعد إكمال التقديم؟"])},
    "a10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حال لزمت الحاجة لطباعة بطاقة مؤقتة بدلا ً عن البطاقة الوطنية لحينتوافرها،حيث من الإمكان طباعتها فورا ً اثناء تواجدك في مركز التدقيق."])},
    "q11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا يعني ظهور الرسالة التالية: \"يرجى الانتظار حتى يصلك فريقنا المختص لإكمال معاملتك من منزلك\"؟"])},
    "a11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تظهر الرسالة عندما يكون رب الأسرة اوأحدالافراد من ذوي الهمم وغير قادر على الذهاب الى مراكز التدقيق"])},
    "q12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل التقديم على البطاقة التموينية الالكترونية مجانياً؟"])},
    "a12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم،التقديم مجاني بالكامل على البطاقة التموينية الالكترونية."])},
    "q13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما الحل لو قمت برفع معلومات خاطئة ورغبت بتصحيحها؟"])},
    "a13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن تصليحها عند مراجعة مركز التدقيق في موعدك المحدد."])},
    "q14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا يعني ظهور الرسالة التالية عند إدخال بطاقتي القديمة \" الرجاء التأكد من معلومات البطاقة التموينية\"؟"])},
    "a14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك مراجعة مركز التدقيق لمعرفة رقم بطاقتك الصحيح او مراجعة وكيل الغذائية الخاص بك."])},
    "q15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا تعني عبارة \"ذوي الهمم\"؟"])},
    "a15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشمل هذه العبارة الأشخاص من ذويالاحتياجات الخاصة وكبار السن والعاجزين."])},
    "q16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند التقديم وجدت نقصان في أحد افراد اسرتي، ما هو الاجراء المناسب في هذه الحالة؟"])},
    "a16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك اكمال عملية التقديم للأفراد المتوفرين فقط ومراجعة أحد المراكز التموينية التي قمت باختيارها عند حجز موعد مراجعتك لإضافة الشخص المفقودد."])},
    "q17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي أوقات عمل مراكز التدقيق لغرض المراجعة؟"])},
    "a17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوقات عمل مراكز التدقيق من يوم السبت حتى يوم الخميس من الساعة الـ8صباحا ً وحتى الـ8 مساءاً."])},
    "q18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حال نسيان او ضياع رقم المستخدم، هل يمكنني استرجاع الرقم؟"])},
    "a18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك استعادة رقم المستخدم عن طريق الضغط على خيار \"نسيت الرمز السري\" وإدخال رقم الهاتف وتأكيده، سيظهر بعدها اسم المستخدم الذي يمكن نسخه وحفظه ويمكنك كذلك تغيير الرمز السري"])},
    "howtouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دليل الاستخدام"])},
    "q19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اﺣد اﻓراد اﻟﻌﺎﺋﻠﺔ ﻏﯾر ﻣﺗواﺟد ﻗرﯾﺑﺎ ﻣن اﻻﺳرة ﻛﯾف ﯾﻣﻛﻧﻧﻲ ﺗﺳﺟﯾﻠﮫ؟"])},
    "a19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ﯾﻣﻛن ارﺳﺎل راﺑط اﻟﺗدﻗﯾق ﻋن طرﯾق ﻧﺳﺧﮫ وارﺳﺎﻟﮫ اﻟﻰ اي ﻣﻧﺻﺔ ﺗﻣﻛﻧك ﻣن اﻟﺗواﺻل ﻣﻊ ﺻﺎﺣب اﻟﺑطﺎﻗﺔ واﺗﺑﺎع ﺧطوات اﻟﺗدﻗﯾق"])}
  },
  "rules": {
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مەرج و ئەحكامەكان"])},
    "ami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەڵێن دەدەم"])},
    "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئامادە بە پێش دەسپێكردنى پرۆسەى ناو تۆماركردن پێويستت بە بەڵگەنامەى ناسنامەى كةسى دةبيت بۆ خۆت و خزانەكەت لە كاتى پرۆسەى تۆماركردندا ."])},
    "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئامادە بە پێش دەسپێكردنى پرۆسەى ناو تۆماركردن پێويستت بە بەڵگەنامەى ناسنامەى كسى دەبێت بۆ خۆت و خزانەكەت لە كاتى پرۆسەى تۆماركردندا ."])},
    "checkbox1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  ئەو ژمارە تەلەفۆنەى لە ڕێگەى ئەوەوە پێشكەش كراوە ڕاستە و ژمارەى تەلەفۆنەكە بە دروستى كار دەكات و لە يەكجار زياتر داواكارى پێشكەش نەكەم ."])},
    "checkbox2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  ڕاپۆرت بدەم ئەگەر من يان ئەندامێكى خێزانەكەم يەكێك بووە لەو كەسانەى كە بڕيارى ژمارە 105 لە ساڵی 2021ئەنجومەنى وەزيران دەيگرێتەوە ( بەڵێندەر ــ خاوەن كۆمپانيا ــ خاوەن پشكەكانى يەكێتى بازرگانان ــ تۆماركراوەكان (لە سەنديكاى پزشكان و پزيشكى ددان و دەرمانسازان) ."])},
    "checkbox3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  كە نە من و نە هيچ يەكێك لە ئەندامانى خێزانەكەم مووچەى زياتر لە دوو مليون دينار وەرناگرين ."])},
    "checkbox4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كە بەڵگەنامەى ڕەسەن بە ئەندامانى خێزانەكەم پێشكەش بكەم كە بەشەخواردن وەردەگرن و ڕاپۆرت بدەم دەربارەى ( گەشتيارەكان ــ خەياڵييەكان ــ مردووەكان ــ ئەوانەى                لە زيندانە ناوەندييەكانن ــ ئەندامانى سەر بەڕِێكخراوە تيرۆرستييەكان ــ هەڵاتووان لە دادپەروەرى) و بە پێچەوانەوە هەموو لێكەوتە ياساييەكانم لە ئەستۆ دەگرم بەپێى مادەى (245) ياساى سزادانى ژمارة (111) بۆيە ڕازى بووم ."])},
    "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دەقى مادەى (245) لە ياساى سزادان : ' سزاى زيندانيكردن كە لە ساڵێك زياتر نەبێت و غەرامەكردن بەسەر هەر كەسێكدا دەسەپێندرێت كە بە شێوەيەكى ياسايى پابەندە بە ئاگاداركردنەوەى كەسێك كە خزمەتگوزارييەكى گشتى پێ سپێردراوە بە پلەى فەرمى خۆى ، و ئاگادارى بكاتەوە لەو بابەتەى كە ئەو دەزانێت كە درۆيە ، هەر كەسێك ، كەسێك كە بە پلەى فەرمى خۆى خزمەتگوزارييەكى گشتى پێسپێردراوە لە بابەتەكان ئاگادار بكاتەوە و كە دەزانێت درۆيە و بە مەبەستى ئەو كارەيە ببنە هۆى ئەوەى كارێك بكات يان ئەنجامدانى كارێكى پپچةوانەى ئەوەى كە دەبوو بيكات ئەگەر لە ئاستى واقيعدا زانيارى لاى هەبووبێت"])},
    "note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*هەموو ئەو زانياريانەى پێشكەشى دەكەيت لەلايەنى دەزگا ئەمنييەكانەوە وردبينيان بۆ دەكرێت ."])},
    "note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* پرۆسى تۆماكردن واتە بەڵێننامە بۆ خوێندنەوە و تێگەيشتن لەمڕێنماييانە و رِازيبوونت بە مەرج و ڕێساكانى پەيوەست بە پسوولەى بەشەخۆراك"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەڵێ"])}
  },
  "login": {
    "regsternumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى تەلەفۆنەکەت تۆماربکە "])},
    "phonenumbermustbe11number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى تەلەفۆن دەبێت لە 11 ژمارە پێکهاتبێت "])},
    "citis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پارێزگا "])},
    "karbala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کەرەبەلای پیرۆز"])},
    "ninwa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نەینەوا  "])},
    "arbil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەولێر  "])},
    "mthna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موسەنا  "])},
    "misan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["میسان  "])},
    "krkok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کەکوک  "])},
    "dhok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دهۆک  "])},
    "diala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دیالە  "])},
    "basra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەسرە  "])},
    "baghdad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەغدا  "])},
    "babil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بابل  "])},
    "anbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئەنبار "])},
    "qadsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قادسیە  "])},
    "salahdin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صڵاحەدین "])},
    "sulimany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلێمانی  "])},
    "najaf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نەجەفی پیرۆز "])},
    "thiqar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زى قار"])},
    "wast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واسیت"])},
    "selectfromlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لە لیستەکە هەڵبژێرە "])},
    "sorryappruninkarbakaonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ببورە ، کاربەرنامەى بەکار تەنها ئێستا لەرێزگای کەربەلاى پیرۆزە "])},
    "reqster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تۆمارکردن "])},
    "youhaveaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەژمارت هەیە؟ "])},
    "enterheretoenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لێرەدا پەنجە بنێ بۆ چوونە ژورەوە"])},
    "enterphonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى تەلەفۆنەکەت داخڵبکە"])},
    "sendotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناردنی کۆد"])},
    "clecktoback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لێرە دا پەنجە بنێ بۆ گەرانەوە "])},
    "confirmpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆدی دڵنیابوون"])},
    "resendpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناردنەوەى کۆماکە دووبارە بکەرەوە  "])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دواتر "])},
    "changephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گۆرینی ژمارەى تەلەفۆنەکەت ؟"])},
    "clickhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لێرەدا پەنجەبنێ "])},
    "cardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى کارتەکە  "])},
    "entercardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى کارتەکە داخڵبکە "])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ووشەى تێپەربوو"])},
    "enterpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ووشەى تێپەربوو داخڵبەکە "])},
    "forgetpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ووشەى نهێنیت لەبیرژەە؟  "])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چوونەژورەوە "])},
    "noaccounthave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەژمارت نییە  ؟ "])},
    "clickheretoreqster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لێرەدا پەنجەبنێ بۆ تۆمارکردن "])},
    "entertmwinyainfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تکایە ئەم زانیاریانەى خوارەوە داخڵبکە لەنێو کارتی بایەعی تایبەت بەتۆ "])},
    "tmwincardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارە بسووڵەى خۆرک "])},
    "numbertmwincenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارە بنکە"])},
    "numberfoodag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى وبریکاری خۆراک"])},
    "numberfluerag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەىبریکاری ئارد"])},
    "personalinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیاری کەسێتی تەواوی خاوەن خێزان "])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گەرانەوە "])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تەواوە "])},
    "personinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیاری کەەسێتی  "])},
    "name1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوی یەکەم "])},
    "name2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوی دووهەم"])},
    "name3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوى سێ هەم"])},
    "name4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوی چوارهە م"])},
    "requerfiled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خانەى پێویست "])},
    "suername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نازناو(ئێختیاریە)"])},
    "mothername1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوى دایک"])},
    "mothername2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوی باوکی دایک"])},
    "mothername3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوی باپیرى دایک"])},
    "gander": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڕەگەز  "])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نێر "])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مێ"])},
    "berthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەرواری لەدایکبوون "])},
    "socualstate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باری خێزانی "])},
    "qtha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قەزا "])},
    "nahia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناحیە"])},
    "marid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیاوى خێزاندار  /-  ئافرەتی خێزاندار "])},
    "singal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کوڕ  /کچ"])},
    "armal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیاوی دەستبەردار  /ئافۆەتی دەست بەردار"])},
    "motalg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیاوی دەستبەردار  /ئافۆەتی دەست بەردار"])},
    "hometype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جۆری نیشتەجێبوون "])},
    "zrai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابۆى کشتوکاڵی  "])},
    "ejar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کرێ"])},
    "tjawz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زێدەرۆیی"])},
    "sfih": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماڵی بەتەنەکە دروستکراو "])},
    "nazh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئاوارە لە کەمپەکان"])},
    "aqarb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لەگەڵ خزمان دادەنیشێت "])},
    "jobtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جۆری کار "])},
    "privet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کەرتی تایبەت "])},
    "publice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" کەرتی گشتی"])},
    "kasib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بێئیش   / كاسب"])},
    "motga3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقاعد"])},
    "cardidtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جۆری کارتی ناسینەوە "])},
    "nationalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناسنامەى نیشتمانی "])},
    "oldedid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناسنامەى باری شارستانی "])},
    "cardidnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى ناسنامە "])},
    "pagenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى لاپەرە"])},
    "lognumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى تۆمار "])},
    "informationOffec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نووسینگەى زانیاری "])},
    "imgidcard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وێنەى کارتی نیشتمانی یاخود ناسنامە "])},
    "deleteimg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سڕینەوەى وێنە "])},
    "frontid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەشی پێشەوە "])},
    "backid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەشی دواوە"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سەیڤکردن"])},
    "familyinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیاری ئەندامانی خێزان"])},
    "withrbosra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پەیوەندی بەخاوەن خێزانەوە "])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خاوەن خێزان "])},
    "wife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیاوی خێزاندار/ / ئافرەتی خێزاندار"])},
    "son": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کوڕ/ کچ"])},
    "parint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دایک /باوک"])},
    "brother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برا / خوشک"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دیکە"])},
    "jobinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیاری  دەربارەى کار "])},
    "socualcera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئایا مووچەى چاودێرانی کۆمەڵایەتی هەیە ؟"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەڵێ "])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نەخێر "])},
    "enterforenglishnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخڵکردن تەنها بۆ ژمارەى ئینگلیزی ڕێگەپێدراوە "])},
    "enterinarabiconly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخڵکردن ڕێگەپێدراوە تەنها بۆ زمانی عەرەبی "])},
    "familynumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى خێزانی لە کارتی نیشتمانی "])},
    "enterforenglishletterandnumberonly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخڵکردن ڕێگەپێدراوە تەنها بۆ ژمارەو پیتی ئینگلیزی "])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لایەنی دەرچوو"])},
    "seeditails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پێشبینی وردەکاری فۆرم"])},
    "cardinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیاری کارت "])},
    "newcardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارە کاتی تازە "])},
    "oldcardnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارە کاتی کۆن"])},
    "centernumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى بنکە"])},
    "adddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەروارى زیادکراو "])},
    "homeinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیاری شوێنی نیشتەجێبوون "])},
    "aqrabpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نزیکترین خاڵی ناونیشان "])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کەسی "])},
    "cardornumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەى کارتی یاخود ناسنامە"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هاوپێچ"])},
    "bookdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کاتگرتن "])},
    "enterdateday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تکایە پەنجەبنێ بەناونیشانی گونجاو بۆ خۆت بە مەبەستی ووردبینی مامەڵەکەت وادەى کاتگرتن هەیە  "])},
    "nobook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هیچ بەروارێکی حجزکردن نییە"])},
    "invaliddata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئەو زانياريانەى كە داخڵكراوە هەڵەيە"])},
    "validfromenterddata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكايە دڵنيا بە لە زانيارييەكانى پسوولەى بەشەخۆراكى"])},
    "confermcodeinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كۆدى پشتڕاستكردنەوەكە هەڵەيە "])},
    "confermsmsmasage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكايە دلَنيا بە لە داخلَكردنى ئەو كؤدەى كە لە رِەگەزنامەى sms ە وە وەرگرتووە"])},
    "invalidllogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تۆماركردنى بەكارهێنانەكەت هەڵەيە  "])},
    "enternumberandpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكايە ژمارەى بەكارهێنەر و وشەى نهێنى كە لە ڕێگەى نامەى كورتەوە بۆت نێردراوە داخڵ بكە ."])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئاگادارکردنەوە"])},
    "confermvalidotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكايە دڵنيا بە لە داخڵ كردنت ئەو كۆدە كە لە ڕێگەى نامەيەكى كورتەوە وەرتگرتووە"])},
    "createaccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دامەزراندنی هەژمار "])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تۆمارکردنی چوونەژورەوە"])},
    "conferm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دووپاتکردنەوە "])},
    "oksubmet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پێشکەشکرا "])},
    "endsubmet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پێشکەشکردن کۆتایی هات"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بەروار  "])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لە "])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاوەکو "])},
    "salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مووچەى مانگانە "])},
    "bookyourdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کاتت بۆ وەرگیرا "])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شەممه "])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["یەکشەممه "])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دووشەممه "])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سێشەممه"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چوارشه ممه"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پێنجشەممه"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمعه"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لینک"])},
    "plscom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تکایە لەکاتی دیاریکراوى خۆت ئامادەبە دڵنیابە لە هێنانی بەڵگەنامەى پێویست بۆ تەواوکردنی کاری ووردبینی بەشێوەى خێراو سەرکەوتوانە "])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چاپکردن "])},
    "editdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵبستە بەڕاستکردنەوەى وادەکەت "])},
    "thedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وادە "])},
    "plshome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تکایە چاوەرێبکە هەتا گروپی شارەزامان دێت بۆ تەواوکردنی مامەڵەکەت لەناوماڵی خۆد  "])},
    "verfictioncode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆدی پشتڕاستکردنەوە بۆ مۆبایلەکەت دەنێردرێت"])},
    "usedphonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەکە بەکاردێت"])},
    "codearydesend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پێشتر وشەی نهێنی بۆ ئەم ژمارەیە نێردراوە"])},
    "sendvalidnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دڵنیابە لە داخڵکردنی ژمارەیەکی دروست"])},
    "usernameorpasswordinvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناوی ئەکاونتەکە یان وشەی نهێنی هەڵەیە"])},
    "confermenterfeilds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دڵنیابە لە چوونە ژوورەوەی مەیدانەکان"])},
    "resendin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دووبارە ناردنەوە لە "])},
    "secnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دووەم"])},
    "resendcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دووبارە کۆدەکە بنێرە"])},
    "verfiecodenotcorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆدی پشتڕاستکردنەوەکە هەڵەیە"])},
    "confermtoenterotpcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دڵنیابە لە داخڵکردنی کۆدی پشتڕاستکردنەوە"])},
    "confirmpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ووشەی نھێنی دووپات - جەخت- دووبارە - بكەوە"])},
    "enterconfempasswordagine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دووبارە وشەی نهێنی داخڵ بکە"])},
    "notconfermpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وشەی نهێنی یەک ناگرێتەوە"])},
    "changepasswordseccses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وشەی نهێنی بە سەرکەوتوویی گۆڕدرا"])},
    "passwordmustbe6ormore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وشەی نهێنی دەبێت لە ٦ پیت یان زیاتر پێکبێت"])},
    "tmwincentermusthave3numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی سەنتەری خواردن و خواردنەوە دەبێت لە ٣ ژمارە پێکبێت"])},
    "foodcenetrmusthave6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی بریکارەکە دەبێت لە ٦ ژمارە پێکبێت"])},
    "tmwinyacardmusthave7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی کارتی بەشەخۆراکی دەبێت لە ٧ ژمارە پێکبێت"])},
    "cardnotexsit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کارتەکە بوونی نییە دڵنیابە لە داخڵکردنی زانیاری دروست"])},
    "familynotcomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاک هەیە کە زانیارییەکانی تەواو نییە"])},
    "uncomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناتەواو"])},
    "yyyymmdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڕۆژ/مانگ/ساڵ"])},
    "formalreadyexist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کارتەکە بەستراوەتەوە بە ژمارەیەکی ترەوە."])},
    "aremainfromhimam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ئایا سەرۆکی بنەماڵەی خەڵکی ئیرادەدارە؟"])},
    "jobtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نازناوی پیشەیی"])},
    "jobnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناسنامەی کار"])},
    "jobImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆپییەکی ناسنامەی کار"])},
    "jobIdCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وێنەی کارت"])},
    "careImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کۆپییەکی کارتی چاودێری کۆمەڵایەتی"])},
    "cardImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وێنەی ناسنامە"])},
    "nationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی پێناسی نەتەوەیی"])},
    "exportdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڕۆژی دەرچون"])},
    "tmwincardmusthave12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی کارتی نیشتمانی دەبێت لە ١٢ ژمارە پێکبێت"])},
    "cardidgnsiamusthave8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی ناسنامە دەبێت لە ٨ ژمارە پێکبێت"])},
    "lognumbermusthave4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی تۆمارکردن دەبێت لە یەک تا 8 ژمارە پێکبێت"])},
    "pagenumbermusthave4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی ڕۆژنامەکە دەبێت لە یەک تا 8 ژمارە پێکبێت"])},
    "familynumbermusthave18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی خێزانەکە دەبێت لە ١٨ ژمارە و پیت پێکبێت"])},
    "familynumbermusthaveletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ژمارەی خێزانەکە دەبێت یەک یان چەند پیتێکی تێدابێت"])},
    "mlksrf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پاشای ئاڵوگۆڕ"])},
    "imgdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وێنەکە سڕاوەتەوە"])},
    "imgnotdeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وێنەکە نەسڕاوەتەوە"])},
    "imgadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وێنەکە زیاد کراوە"])},
    "imgnotadded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وێنەکە زیاد نەکراوە"])},
    "invalidinworkinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵەیەک لە زانیاری کارەکاندا هەیە... تکایە نووسراوەکان بپشکنە"])},
    "invalidinnationaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵەیەک لە زانیاری کارتەکەدا هەیە... تکایە نووسراوەکان بپشکنە"])},
    "invalidincardid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵەیەک لە زانیارییەکانی ناسنامەدا هەیە... تکایە زانیارییەکانی ناوەوە بپشکنە"])},
    "dataseved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زانیارییەکان پاشەکەوت کراون"])},
    "invailddata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هەڵەیەک لە زانیارییەکاندا هەیە... تکایە زانیارییەکانی ناوەوە بپشکنە"])},
    "qraba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پەیوەندی ڕێژەیی"])},
    "invalidimage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من رفع صورة البطاقة التعريفية\t"])},
    "invaliddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من ادخال تاريخ الميلاد\t"])},
    "imgsize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الملف يجب أن يكون بين 50 كيلو بايت و 5 ميجا بايت"])},
    "loginagine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستصلك رسالة بمعلومات حسابك"])},
    "invaildreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطا"])}
  }
}