<template>
  <div class="splash-screen">
    <img src="./logo.svg" alt="Logo" class="" />
    <p class="pt-n12">... جاري التحميل</p>
  </div>
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      this.$emit("remove-splash");
    }, 100);
  },
};
</script>

<style scoped>
.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

img {
  width: 200px;
  height: 200px;
  margin-bottom: 16px;
}

p {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}
</style>
