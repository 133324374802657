import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import i18n from "./i18n";
import axios from "axios";
import { MotionPlugin } from "@vueuse/motion";
import vuetify from "./plugins/vuetify";
import { useCounterStore } from "./store/index";
import "@fortawesome/fontawesome-free/css/all.css";



createApp(App)
  .use(MotionPlugin)
  .use(axios)
  .use(i18n)  
  .use(createPinia())
  .use(router)
  .use(vuetify)
  .mount("#app");
