// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { VField } from "vuetify/components/VField";
import i18n from "@/i18n";
import { ar, en } from "vuetify/locale";

// Vuetify
import { createVuetify } from "vuetify";
import { VDataTable } from "vuetify/labs/VDataTable";
const isDark = localStorage.getItem("isDark") === "true";
const theme = {
  dark: false,
  colors: {
    primary: "#1FA35E",
    secondary: "#FFC000",
    accent: "#9C27b0",
    bg: "#f4f5fa",
    white: "#ffffff",
    "on-secondary": "#fff",
    success: "#56CA00",
    info: "#16B1FF",
    warning: "#FFB400",
    error: "#FF4C51",
    "on-primary": "#FFFFFF",
    "on-success": "#FFFFFF",
    "on-warning": "#FFFFFF",
    background: "#eff3f8",
    "on-background": "#3A3541",
    "on-surface": "#3A3541",
    "on-surface2": "#a9c9f0",
    "grey-50": "#FAFAFA",
    "grey-100": "#F5F5F5",
    "grey-200": "#EEEEEE",
    "grey-300": "#E0E0E0",
    "grey-400": "#BDBDBD",
    "grey-500": "#9E9E9E",
    "grey-600": "#757575",
    "grey-700": "#616161",
    "grey-800": "#424242",
    "grey-900": "#212121",
  },
  variables: {
    "border-color": "#3A3541",
    "medium-emphasis-opacity": 0.68,

    // Shadows
    "shadow-key-umbra-opacity": "rgba(var(--v-theme-on-surface2), 0.08)",
    "shadow-key-penumbra-opacity": "rgba(var(--v-theme-on-surface2), 0.12)",
    "shadow-key-ambient-opacity": "rgba(var(--v-theme-on-surface2), 0.04)",
  },
};

export default createVuetify({
  components: {
    VDataTable,
    VField,
  },
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: "لا يوجد عناصر",
    },
  },
  VField: {
    border: "15px solid red",
    borderColor: "red",
  },
  VBtn: {
    // set v-btn default color to primary
    color: "primary",
  },

  locale: {
    locale: "ar",
    fallback: "en",
    messages: { ar, en },
  },
  display: {
    mobileBreakpoint: "sm",
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    },
  },
  icons: {
    iconfont: "fa",
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    defaultTheme: isDark ? "dark" : "light",
    themes: {
      dark: {
        dark: true,
        colors: {
          primary: "#1FA35E",
          secondary: "#8A8D93",
          "on-secondary": "#fff",
          success: "#56CA00",
          info: "#16B1FF",
          warning: "#FFB400",
          error: "#FF4C51",
          "on-primary": "#091427",
          "on-success": "#091427",
          "on-warning": "#091427",
          background: "#050d1a",
          "on-background": "#E7E3FC",
          surface: "#091427",
          "on-surface": "#E7E3FC",
          "grey-50": "#2A2E42",
          "grey-100": "#2F3349",
          "grey-200": "#4A5072",
          "grey-300": "#5E6692",
          "grey-400": "#7983BB",
          "grey-500": "#8692D0",
          "grey-600": "#AAB3DE",
          "grey-700": "#B6BEE3",
          "grey-800": "#CFD3EC",
          "grey-900": "#E7E9F6",
        },
        variables: {
          "border-color": "#0f2140",
          "medium-emphasis-opacity": 0.68,

          // Shadows
          "shadow-key-umbra-opacity": "rgba(20, 18, 33, 0.08)",
          "shadow-key-penumbra-opacity": "rgba(20, 18, 33, 0.12)",
          "shadow-key-ambient-opacity": "rgba(20, 18, 33, 0.04)",
        },
      },
      light: theme,
    },
  },
});
