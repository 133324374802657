import { defineStore } from "pinia";
import router from "@/router/index";

export const useCounterStore = defineStore("counter", {
  state: () => ({
    loading: false,
    rail: true,
    drawer: false,
    isDark: localStorage.getItem("isDark"),
  
  }),
  getters: {
    isRail(state) {
      if (state.rail === true) return true;
      return false;
    },
  },
  actions: {
    railChange() {
      this.rail = !this.rail;
    },
    drawerChange() {
      this.drawer = !this.drawer;
    },

  },
});
